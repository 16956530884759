@import url('https://api.fontshare.com/v2/css?f[]=satoshi@900,700,500,301,701,300,501,401,901,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');
.app {
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  header { 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    text-align: center;
    }
    
    p {
      color: #94a3b8;
    }
  
  
  .tag-list {
    width: 50rem;
    max-width: 90vw;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: 1rem 0;
    position: relative;
    padding: 1.5rem 0;
    overflow: hidden;
  }
  
  .loop-slider {
    .inner {
      display: flex;
      width: fit-content;
      animation-name: loop;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: var(--direction);
      animation-duration: var(--duration);
    }
  }
  
  .tag {
    background-color: '#A1A1A1';
    border-radius: 2rem;
    display: flex;
    align-items: center;
    gap: 0 0.2rem;
    color: #293451;
    font-size: 0.9rem;
    border-radius: 0.4rem;
    padding: 0.7rem 1rem;
    margin-right: 1rem; 
  }
  .fade {
    pointer-events: none;
    background: linear-gradient(90deg, #fff, transparent 30%, transparent 70%, #fff);
    position: absolute;
    inset: 0;
  } 
  @keyframes loop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
.logoFont {
    font-family: 'Alata', sans-serif;
    font-size: 32px;
    background: linear-gradient(to right, #4BCFBB 0%, #4BCFBB 100%);
    color: "#4BCFBB" !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.logoFoot {
    font-family: 'Alata', sans-serif;
    font-size: 45px;
    background: linear-gradient(to right, #4BCFBB 0%, #4BCFBB 100%);
    color: "#8CCAAA" !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.titre1{
    font-family: 'Satoshi', sans-serif;
    font-weight: 900;
    color: #3B3B3B;
}
body {
    overflow-x: hidden;
}
.fontPara{
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    background: linear-gradient(to right, #BDC6CB 0%, #BDC6CB 100%);
    color: "#799492" !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontParaWhite{
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    background: linear-gradient(to right, #fff 0%, #fff 100%);
    color: "#fff" !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontParaWhite{
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    background: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
    color: "#799492" !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontParaBold{
    font-family: 'Satoshi', sans-serif;
    font-weight: 500;
    background: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
    color: "#799492" !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontParaPetit{
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    background: linear-gradient(to right, #A1A1A1 0%, #A1A1A1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontParaPetitJust{
    font-family: 'Satoshi', sans-serif;
    test-decoration: justify;
    font-weight: 400;
    background: linear-gradient(to right, #A1A1A1 0%, #A1A1A1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.blockAnnonce {
    box-shadow: 0px 0px 3px 2px #D9DDD6;   
}
.container2 {

  }
  
.blockAnnonce:hover{
    transition: 0.5s;
    box-shadow: 0px 0px 3px 3px #BDC6CB !important;
    cursor: pointer;
    
}
.fontGrey{
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    background: linear-gradient(to right, #7D8784 0%, #7D8784 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontClassic{
    font-family: 'Satoshi', sans-serif;
}
.fontThin{
    font-family: 'Satoshi', sans-serif;
    font-weight: 200;
    color: #7D8784;
}
.fontSlide{
    font-family: 'Satoshi', sans-serif;
    font-weight: 500;
    font-size: 17;
    color: #293451;
}
.fontSlide2{
    font-family: 'Satoshi', sans-serif;
    font-weight: 500;
    font-size: 17;
    color: #4BCFBB;
}
.space{
    margin-top: 20px;
}
.fontKThin{
    font-family: 'Kiona', sans-serif;
    font-weight: 200;
    color: #BDC6CB;
}
.fontKThin2{
    font-family: 'Satoshi', sans-serif;
    font-weight: 200;
    color: #BDC6CB;
}
.fontKBold{
    font-family: 'Satoshi', sans-serif;
    font-weight: 200;
    color: #BDC6CB;
}
.fontKBoldGold{
    font-family: 'Satoshi', sans-serif;
    font-weight: 200;
    color: #8CCAAA;
}
.fontKionaGoldBold{
    font-family: 'Satoshi', sans-serif;
    font-weight: 500;
    color: #8CCAAA;
}
.sousTitre{
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
    color: #2D2D2D;
}
.redpass{
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    color: #FF0000;
    font-size: 13px;
    font-style: italic;
}
.fontBold{
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
    color: white;
}
.smallfont{
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    color: #2D2D2D;
    font-size: 13px;
}
.fontBold{
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
    color: #2D2D2D;
}
.bigWord{
    font-family: 'Satoshi', sans-serif;
    color:"#B68D40";
    background: linear-gradient(to right, #F5ECD0 0%, #D7AD61 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}
.bigWordThin{
    font-family: 'Satoshi', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    background: linear-gradient(to right, #0F2022 0%, #0F2022 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}
.bigWordThin2{
    font-family: 'Satoshi', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    background: linear-gradient(to right, #4BCFBB 0%, #4BCFBB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}
.whiteTitle{
    font-family: 'Satoshi', sans-serif;
    font-weight: 500;
    background: linear-gradient(to right, #fff 0%, #fff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}
.blackTitle{
    font-family: 'Satoshi', sans-serif;
    font-weight: 500;
    background: linear-gradient(to right, #2D2D2D 0%, #2D2D2D 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}

.fontForm{
    font-family: 'Satoshi', sans-serif;
    font-weight: 500;
    background: linear-gradient(to right, #A1A1A1 0%, #A1A1A1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}
.fontKionaGold{
    font-family: 'Satoshi', sans-serif;
    color:"#283451";
    text-transform: uppercase;
    font-weight: 400;
    background: linear-gradient(to right, #283451 0%, #283451 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}

.fontKionaBlue{
    font-family: 'Satoshi', sans-serif;
    color:"#4BCFBB";
    text-transform: uppercase;
    font-weight: 400;
    background: linear-gradient(to right, #4BCFBB 0%, #4BCFBB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}

.fontKionaGold2{
    font-family: 'Satoshi', sans-serif;
    color:"#8CCAAA";

    font-weight: 400;
    background: linear-gradient(to right, #8CCAAA 0%, #8CCAAA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}
.titleCustom{
    color: #D7AD61;
    font-family: 'Satoshi', sans-serif;
    font-weight: 700;
    background: linear-gradient(to right, #D7AD61 0%, #D7AD61 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.button1{
    border-width: 1px;
    border-radius: 10Px;
    border-color: "##DAA686";
}
.text-button1{
    color: "##DAA686"
}
.divGen{
    background-color: #EDFAF8;
    margin-bottom: 0px;
}
.titleBack{
    font-family: 'Satoshi', sans-serif;
    font-size: 13px;
    align-self: center;
    background: linear-gradient(to right, #fff 0%, #fff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontBtn {
    font-family: 'Satoshi', sans-serif;
    font-size: 13px;
    align-self: center;
    background: linear-gradient(to right, #B68D40 0%, #B68D40 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fontBtn2 {
    font-family: 'Satoshi', sans-serif;
    font-size: 13px;
    align-self: center;
    background: linear-gradient(to right, #BDC6CB 0%, #BDC6CB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#rotating-text {
    animation: rotation 30s infinite linear; /*CHANGE SPEED*/
    width: 150px;
    z-index: 30;
    align-items: center;
    left: -70px;
    top: -70px;
    position: absolute;
}

@keyframes rotation {
from {
transform: rotate(0deg);
}

to {
transform: rotate(360deg);
}
}
#rotating-text textPath { 
    /*---------------- CHANGE TEXT APPEARANCE ----------------*/
    font-size: 65px; 
    font-family: 'Satoshi', sans-serif;
    text-transform: uppercase;
    letter-spacing: 20px; 
    fill: #B68D40; 
}
/* .cursor {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: var(--width);
    height: var(--height);
    transform: translate(calc(var(--x) - var(--width) / 2), calc(var(--y) - var(--height) / 2));
    transition: 150ms width cubic-bezier(0.39, 0.575, 0.565, 1),
                150ms height cubic-bezier(0.39, 0.575, 0.565, 1),
                150ms transform cubic-bezier(0.39, 0.575, 0.565, 1);
    z-index: 1;
    pointer-events: none;
    will-change: transform;
  } */
/*   @media (pointer: fine) {
    .cursor {
      display: block;
    }
  }
  .cursor::after {
    content: '';
    position: absolute;
    color: #F5ECD0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--radius);
    border: 2px solid #F5ECD0;
    opacity: 0.5;
    -webkit-transform: scale(var(--scale));
            transform: scale(var(--scale));
    transition: 300ms opacity cubic-bezier(0.39, 0.575, 0.565, 1),
                300ms transform cubic-bezier(0.39, 0.575, 0.565, 1),
                150ms border-radius cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  html {
    cursor: url("data:image/svg+xml,%3Csvg height='6' width='6' viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' fill='%23eee' r='4'/%3E%3C/svg%3E") 3 3, auto;
    overflow-x: hidden;
  }   */

  .playerCss{
    width: 700px;
    height: 900px;
  }