@import url('https://api.fontshare.com/v2/css?f[]=satoshi@900,700,500,301,701,300,501,401,901,400&display=swap');


.titleGrad {
    font-family: 'Satoshi', sans-serif;
    font-weight: 700;
    background: linear-gradient(to right, #2E3332 0%, #2E3332 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.titleLogo {
    font-family: 'Satoshi', sans-serif;
    font-size: 20px;
    align-self: center;
    margin-left: 7px;
    background: linear-gradient(to right, #F5ECD0 0%, #B68D40 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.titleGradRed {
    background: linear-gradient(to right, #E66290 0%, #E63572 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.titleGradOrange {
    background: linear-gradient(to right, #FEC473 0%, #FEA120 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 990px) {
    .imageFirstSec{
        display: none;
    }
}

.css-mycl9f {
    display: grid;
    position: relative;
    min-height: 90vh;
    opacity: 1;
    background: #102022;
}